<template>
  <span>{{ getProductsCountSel() }}</span>
</template>

<script>
import get from "lodash/get";
export default {
  name: "InvoicesTableProductsCountSel",

  methods: {
    getProductsCountSel() {
      const n_prods = get(this.data, "n_prods", null);
      const n_prods_sel = get(this.data, "n_prods_sel", null);

      return n_prods_sel !== null
        ? `${n_prods_sel} di ${n_prods}`
        : `${n_prods}`;
    },
  },
};
</script>
